import { ElementBaseM } from "@/models";
import { ElementI } from "@/interfaces";
const ElementIconM = Object.assign(ElementBaseM, {
  type: "state-icon",
  icon: "",
  state_color: "",
  tap_action: {
    action: "toggle",
  },
  hold_action: {
    action: "toggle",
  },
  double_tap_action: {
    action: "toggle",
  },
  style: {
    top: 0,
    left: 0,
    transform: "scale(1)",
    transformOrigin: "top left",
  },
}) as ElementI;

export { ElementIconM };
