import _Vue from 'vue';
import { VueClass } from 'vue-class-component/lib/declarations';

declare module 'vue/types/vue' {
    interface Vue {
        getRemoteComponent: (refComponentName: string, scope: any) => HTMLFormElement;
        serviceProvider: any;
    }
}

export function ServiceProvider(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.getRemoteComponent = (refComponentName: string, scope: any): HTMLFormElement => {
        return scope.$refs[refComponentName] as HTMLFormElement;
    };

    Vue.prototype.serviceProvider = {};
    Object.keys(options).forEach((serviceName: string) => {
        if (!Vue.prototype.serviceProvider[serviceName]) {
            Vue.prototype.serviceProvider[serviceName] = new options[serviceName];
        }
    });
}