import Vue from "vue";
import LocalStorageService from "@/services/LocalStorage.service";
import { PictureElementI, StorageSettingsI, ElementI, EntityI } from "@/interfaces";

class EventBus extends Vue {
  $onAll(callback: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.onAll = callback;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $emit(event, ...args) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.onAll && typeof this.onAll.call === "function") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onAll(event, ...args);
    }

    return super.$emit(event, ...args);
  }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const eventBus = new EventBus();

eventBus.$on("UPDATE_STORAGE", (storage: { pictureElement: PictureElementI; settings: StorageSettingsI }) => {
  LocalStorageService.saveAllData(storage.pictureElement, storage.settings);
  eventBus.$emit("STORAGE_UPDATED", storage);
});

eventBus.$onAll((x: any) => {
  console.log("EVENT CALLED: ", x);
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default eventBus;
