
class StorageService {
  protected storage = null as unknown;
  setStorage(data: string) {
    this.storage = this.storage + data;
    return this.storage;
  }
  getStorage() {
    return this.storage;
  }
}
export default StorageService;
