import { StorageSettingsI, PictureElementI, ElementI } from "@/interfaces";

const StorageSettingsM = {
  image: "",
  ha_topOffset: "-3.5",
  ha_leftOffset: "-2.5",
  ha_entities_endpoint: "",
  importedEntities: [{ entity_id: "light.example", state: "ON" }, { entity_id: "light.example2", state: "OFF" }],
} as StorageSettingsI;

export { StorageSettingsM };

const StoragePictureElementM = {
  image: "",
  elements: [] as ElementI[],
  type: "picture-elements",
} as PictureElementI;

export { StoragePictureElementM };
