import _Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    vDragon: any;
  }
}

export default function VDragon(Vue: typeof _Vue, options?: any): void {
  function makeid(length: number) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  Vue.directive("dragon", {
    inserted: function (el, binding, vnode) {
      let elClasso = "";
      let offset = [0, 0];
      let down = false;
      el.style.position = "absolute";
      el.style.cursor = "pointer";
      if (el.parentElement) {
        if (["absolute", "relative"].indexOf(el.parentElement.style.position) === -1) {
          el.parentElement.style.position = "relative";
          //el.parentElement.style.border = "1px solid red";
        }
      }

      function getPosition(element: HTMLElement) {
        const dimensions = element.getBoundingClientRect();
        if (!element.parentElement) {
          return {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            xP: "0%",
            yP: "0%",
            outX: 0,
            outY: 0,
          };
        }

        const dimensionsParent = element.parentElement.getBoundingClientRect();
        return {
          x: dimensions.left - dimensionsParent.left,
          y: dimensions.top - dimensionsParent.top,
          width: dimensions.width,
          height: dimensions.height,
          xP: (((dimensions.left - dimensionsParent.left) / dimensionsParent.width) * 100).toFixed(2) + "%",
          yP: (((dimensions.top - dimensionsParent.top) / dimensionsParent.height) * 100).toFixed(2) + "%",
          outX: dimensionsParent.width - (dimensions.left - dimensionsParent.left + dimensions.width),
          outY: dimensionsParent.height - (dimensions.top - dimensionsParent.top + dimensions.height),
        };
      }

      function getTargetBordersWidth(el: HTMLElement) {
        return {
          leftRight: parseFloat(el.style.borderLeftWidth) + parseFloat(el.style.borderRightWidth),
          topBottom: parseFloat(el.style.borderLeftWidth) + parseFloat(el.style.borderRightWidth),
        };
      }

      function customEvent(eventName: string) {
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit(eventName, {
            detail: getPosition(el),
          });
        } else {
          vnode.elm?.dispatchEvent(
            new CustomEvent(eventName, {
              detail: getPosition(el),
            }),
          );
        }
      }

      el.addEventListener("mousedown", (e) => {
        down = true;
        elClasso = makeid(20);
        el.classList.add(elClasso);
        el.classList.add("v-dragon-class");
        customEvent("move-start");
        offset = [el.offsetLeft - e.clientX, el.offsetTop - e.clientY];
      });
      if (el.parentElement)
        el.parentElement.addEventListener(
          "mouseup",
          function () {
            down = false;
            if (el.classList.contains(elClasso)) {
              el.classList.remove("v-dragon-class");
              const elPos = getPosition(el);
              if (elPos.x < 0) {
                el.style.left = "0px";
              }
              if (elPos.y < 0) {
                el.style.top = "0px";
              }
              if (elPos.outX < 0) {
                el.style.left = (elPos.x + elPos.outX).toFixed(0) + "px";
              }
              if (elPos.outY < 0) {
                el.style.top = (elPos.y + elPos.outY).toFixed(0) + "px";
              }
              el.classList.remove(elClasso);
              customEvent("move-end");
            }
          },
          true,
        );
      if (el.parentElement)
        el.parentElement.addEventListener(
          "mousemove",
          function (e) {
            e.preventDefault();
            if (down) {
              el.style.left = e.clientX + offset[0] + "px";
              el.style.top = e.clientY + offset[1] + "px";
              customEvent("move-progress");
            }
          },
          true,
        );
    },
  });
}
