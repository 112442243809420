



































































































import Vue from "vue";
import * as MdiIcons from "@/static/mdi-icons";
import { ElementTypesM, ElementActionsM } from "@/models";
import { StorageSettingsI, ElementI, EntityI, StorageI } from "@/interfaces";
import EventBus from "@/events/EventBus";

export default Vue.extend({
  name: "ElementParams",
  data() {
    return {
      mdiIcons: MdiIcons,
      types: ElementTypesM,
      actions: ElementActionsM,
      content: (this.value || null) as ElementI,
      entities: [] as EntityI[],
      storedSettings: {
        image: "",
        ha_entities_endpoint: "",
      } as StorageSettingsI,
    };
  },
  created() {
    EventBus.$on("STORAGE_DATA_UPDATED", (storage: StorageI) => {
      this.entities = storage.settings.importedEntities;
    });
  },
  methods: {
    forceInput() {
      this.content.title = this.content.title + " ";
      setTimeout(() => {
        this.content.title = this.content.title.trimLeft();
      }, 100);
    },
    input() {
      this.$emit("input", this.content);
    },
  },
  watch: {
    content: {
      handler(n, o) {
        if (n !== o) {
          this.$emit("input", n);
        }
      },
      deep: true,
    },
    "$props.value": {
      handler(v) {
        this.content = v;
      },
      deep: true,
    },
  },
  props: ["value"],
});
