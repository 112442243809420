













































import Vue from "vue";
import LocalStorageService from "@/services/LocalStorage.service";
import { StorageSettingsI, ImportedEntityI } from "@/interfaces";
import YAML from "yaml";

export default Vue.extend({
  name: "ImportData",

  data() {
    return {
      importedYamlText: "",
      importedYaml: [] as ImportedEntityI[],
      text: "",
      snackbar: false,
      timeout: 2000,
      model: this.value || (false as boolean),
      yaml: false,
    };
  },
  created() {
    const storage = LocalStorageService.getStorage();
    this.importedYaml = storage.settings.importedEntities || [];
    this.importedYamlText = YAML.stringify(storage.pictureElement);
  },
  watch: {
    model: {
      handler(n, o) {
        if (n !== o) {
          this.$emit("input", n);
        }
      },
      deep: true,
    },
    "$props.value": {
      handler(v) {
        this.model = v;
      },
      deep: true,
    },
  },
  props: ["value"],
  methods: {
    importData() {
      try {
        let counter = 0;

        this.text = counter + " Entites sucessfuly imported";
        this.snackbar = true;
      } catch (e: any) {
        this.text = e.message;
        this.snackbar = true;
        return false;
      }
      const storage = LocalStorageService.loadAllData();
    },
  },
});
