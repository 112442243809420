













































import Vue from "vue";
import LocalStorageService from "@/services/LocalStorage.service";
import { StorageSettingsI, ImportedEntityI } from "@/interfaces";
import YAML from "yaml";

export default Vue.extend({
  name: "ImportEntities",

  data() {
    return {
      importedEntitiesText: "",
      importedEntities: [] as ImportedEntityI[],
      text: "",
      snackbar: false,
      timeout: 2000,
      model: this.value || (false as boolean),
      yaml: false,
    };
  },
  created() {
    const storage = LocalStorageService.getStorage();
    this.importedEntities = storage.settings.importedEntities || [];
    this.importedEntitiesText = JSON.stringify(storage.settings.importedEntities, null, 4);
  },
  watch: {
    model: {
      handler(n, o) {
        if (n !== o) {
          this.$emit("input", n);
        }
      },
      deep: true,
    },
    "$props.value": {
      handler(v) {
        this.model = v;
      },
      deep: true,
    },
  },
  props: ["value"],
  methods: {
    importEntites() {
      try {
        this.importedEntities = JSON.parse(this.importedEntitiesText);
        let counter = 0;
        this.importedEntities = this.importedEntities.filter((entity) => {
          if (entity.entity_id) {
            counter++;
            return true;
          } else {
            false;
          }
        });
        this.text = counter + " Entites sucessfuly imported";
        this.snackbar = true;
      } catch (e: any) {
        this.text = e.message;
        this.snackbar = true;
        return false;
      }
      const storage = LocalStorageService.loadAllData();
      storage.settings.importedEntities = this.importedEntities;
      LocalStorageService.saveAllData(storage.pictureElement, storage.settings);
    },
  },
});
