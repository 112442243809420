





































import Vue from "vue";
import LocalStorageService from "@/services/LocalStorage.service";
import { PictureElementI, ElementI, StorageSettingsI, StorageI } from "@/interfaces";

import YAML from "yaml";

export default Vue.extend({
  name: "ExportData",

  data() {
    return {
      textarea: "",
      text: "",
      snackbar: false,
      timeout: 1000,
      model: this.value || (false as boolean),
      yaml: true,
      settings: {} as StorageSettingsI,
      configuration: {} as PictureElementI,
      configurationJSON: "" as string,
      configurationYAML: "" as string,
    };
  },
  created() {
    const storedData = JSON.parse(JSON.stringify(LocalStorageService.loadAllData())) as StorageI;
    this.settings = storedData.settings as StorageSettingsI;
    this.configuration = this.fixConfiguration(storedData.pictureElement) as PictureElementI;
    this.configurationJSON = JSON.stringify(this.configuration, null, 4);
    this.configurationYAML = YAML.stringify(this.configuration);
    this.textarea = this.configurationYAML;
  },
  watch: {
    yaml: {
      handler(n) {
        this.textarea = n ? this.configurationYAML : this.configurationJSON;
      },
    },
    model: {
      handler(n, o) {
        if (n !== o) {
          this.$emit("input", n);
        }
      },
      deep: true,
    },
    "$props.value": {
      handler(v) {
        this.model = v;
      },
      deep: true,
    },
  },
  props: ["value"],
  methods: {
    fixConfiguration(configuration: PictureElementI) {
      configuration.elements = configuration.elements.map((element: ElementI) => {
        if (element.icon) {
          element.icon = "mdi:" + element.icon;
        }
        const topOffset = this.settings.ha_topOffset ? parseFloat(this.settings.ha_topOffset) : 0;
        const leftOffset = this.settings.ha_leftOffset ? parseFloat(this.settings.ha_leftOffset) : 0;

        if (element.style.top) {
          element.style.top = (parseFloat(element.style.top.replace("%", "")) + topOffset).toFixed(2) + "%";
        }
        if (element.style.left) {
          element.style.left = (parseFloat(element.style.left.replace("%", "")) + leftOffset).toFixed(2) + "%";
        }
        return element;
      });
      return configuration;
    },
    copyTestingCode() {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const x = this.$refs.textToCopy.select();
        document.execCommand("copy");
        this.text = "Copied...";
      } catch (err) {
        this.text = "Oops, unable to copy";
      }
      this.snackbar = true;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.getSelection().removeAllRanges();
    },
  },
});
