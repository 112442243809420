






































import Vue from "vue";
import EventBus from "@/events/EventBus";

export default Vue.extend({
  name: "App",

  data: () => ({
    selectedItem: null,
    menu: [
      {
        title: "Import Entities",
        click: () => {
          EventBus.$emit("ImportEntities", true);
        },
      },
      {
        title: "Export Yaml",
        click: () => {
          EventBus.$emit("OpenExportData", true);
        },
      },
      // {
      //   title: "Import Yaml",
      //   click: () => {
      //     // EventBus.$emit("OpenImportData", true);
      //   },
      // },
      {
        title: "Clear Editor Data",
        click: () => {
          if (confirm("Clear All Editor Data?")) {
            localStorage.removeItem("picture-elements-editor-data");
            localStorage.removeItem("picture-elements-editor-settings");
            window.location.reload();
          }
        },
      },
      {
        title: "Author: pee@analogbrain.com",
        click: () => {
          location.href = "mailto:pee@analogbrain.com?&subject=PictureElementsEditorIsseu&body=What need to be fixed";
        },
      },
    ],
  }),
});
