

























































































































































































import Vue from "vue";
import ExportData from "../components/ExportData.vue";
import ImportData from "../components/ImportData.vue";
import ImportEntities from "../components/ImportEntities.vue";
import EventBus from "@/events/EventBus";
import LocalStorageService from "@/services/LocalStorage.service";
import { PictureElementI, StorageSettingsI, ElementI, EntityI, StorageI } from "@/interfaces";
import ElementParams from "@/components/ElementParams.vue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const element = {
  entity: "",
  title: "",
  icon: "alpha-e-circle",
  label: "",
  style: {
    top: 0,
    left: 0,
    transform: "scale(1)",
    transformOrigin: "top left",
  },
  type: "state-icon",
};

export default Vue.extend({
  name: "HomeView",
  directives: {},
  data() {
    return {
      exportDialogOpen: false as boolean,
      importDialogOpen: false as boolean,
      importEntitiesDialogOpen: false as boolean,
      debug: {
        icon: null as any,
        frame: null as any,
      },
      editedElement: null as null | ElementI,
      data: {
        image: "",
        elements: [] as ElementI[],
        type: "picture-elements",
      } as PictureElementI,
      storedSettings: {
        image: "",
        ha_topOffset: "-3.5",
        ha_leftOffset: "-2.5",
        ha_entities_endpoint: "",
        importedEntities: [],
      } as StorageSettingsI,
    };
  },
  async created() {
    EventBus.$on("STORAGE_DATA_UPDATED", (storage: StorageI) => {
      this.storedSettings = storage.settings;
      this.data = storage.pictureElement;
    });
    EventBus.$on("OpenExportData", () => {
      this.exportDialogOpen = true;
    });
    EventBus.$on("ImportEntities", () => {
      this.importEntitiesDialogOpen = true;
    });
    EventBus.$on("OpenImportData", () => {
      this.importDialogOpen = true;
    });

    this.serviceProvider.StorageService.setStorage("dddd");
  },
  watch: {
    storedSettings: {
      handler() {
        this.updateStorage();
      },
      deep: true,
    },
    data: {
      handler() {
        this.updateStorage();
      },
      deep: true,
    },
  },
  methods: {
    getState(element: ElementI) {
      const entity = this.storedSettings.importedEntities.find((e) => e.entity_id === element.entity);

      return entity ? entity?.state : "STATE";
    },
    moveStart(e: any, element: ElementI) {
      this.editedElement = element;
    },
    moveEnd(e: any, element: ElementI) {
      element.style.left = e.detail.xP;
      element.style.top = e.detail.yP;
    },
    updateStorage() {
      LocalStorageService.saveAllData(this.data, this.storedSettings);
    },
    getRemoteComponent(refComponentName: string): HTMLFormElement {
      return this.$refs[refComponentName] as HTMLFormElement;
    },
    getSelectedClass(element: ElementI) {
      return element === this.editedElement ? "element-selected-class" : "element-item-class";
    },
    addElement() {
      var newElement = JSON.parse(JSON.stringify(element));
      newElement.title = "New Entity " + (this.data.elements.length + 1);
      this.data.elements.push(newElement);
    },
    onFileChange(e: File) {
      var files = [e];
      if (!files.length) {
        return;
      }
      this.data.image = "/local/plans/" + e.name;
      this.createImage(files[0]);
    },
    createImage(file: File) {
      var reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reader.onload = (e: FileReader) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.storedSettings.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    getElementsList() {
      const output = {
        unregistered: [] as ElementI[],
      };

      this.data.elements.forEach((element: ElementI) => {
        const entity = element.entity + "";
        if (entity === "" || entity === null) {
          output.unregistered.push(element);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (output[entity.split(".")[0]]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            output[entity.split(".")[0]] = element;
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            output[entity.split(".")[0]] = [element];
          }
        }
      });
      return output;
    },
  },
  components: { ElementParams, ExportData, ImportEntities, ImportData },
});
