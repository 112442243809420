import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import EventBus from "@/events/EventBus";
import YAML from "yaml";
import vDragon from "@/plugins/v-dragon";

fetch("https://api.crackedcube.com:1880/ppeUpdateCounter", {});

import { ServiceProvider } from "@/plugins/ServiceProvider";
import StorageService from "@/services/Storage.service";

Vue.use(ServiceProvider, {
  StorageService,
});
Vue.use(vDragon, {});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
