import { StorageSettingsI, PictureElementI, StorageI } from "@/interfaces";
import { StorageSettingsM, StoragePictureElementM } from "@/models";
import eventBus from "@/events/EventBus";
class LocalStorageService {
  protected storage = {
    pictureElement: StoragePictureElementM,
    settings: StorageSettingsM
  } as StorageI;
  protected loaded = false as boolean;

  constructor() {
    console.log('new');
  }

  saveAllData(pictureElement: PictureElementI, settings: StorageSettingsI): void {
    this.storage = {
      pictureElement,
      settings
    };
    localStorage.setItem("picture-elements-editor-data", JSON.stringify(pictureElement));
    localStorage.setItem("picture-elements-editor-settings", JSON.stringify(settings));
    eventBus.$emit('STORAGE_DATA_UPDATED', this.storage);
  }
  loadAllData(): { pictureElement: PictureElementI; settings: StorageSettingsI } {
    if (this.loaded) {
      return this.getStorage();
    }
    const pictureElement = localStorage.getItem("picture-elements-editor-data") as string;
    const settings = localStorage.getItem("picture-elements-editor-settings") as string;
    this.storage = {
      pictureElement: JSON.parse(pictureElement) || StoragePictureElementM,
      settings: JSON.parse(settings) || StorageSettingsM
    };
    this.loaded = true;
    eventBus.$emit('LOADED_STORAGE', this.storage);
    eventBus.$emit('STORAGE_DATA_UPDATED', this.storage);
    return this.storage;
  }
  getStorage() {
    if (!this.loaded) {
      return this.loadAllData();
    }
    return this.storage;
  }
}
export default new LocalStorageService();